export const radius = {
  0: 0,
  1: 3,
  2: 5,
  3: 7,
  4: 9,
  true: 9,
  5: 10,
  6: 16,
  7: 19,
  8: 22,
  9: 26,
  10: 34,
  11: 42,
  12: 50,
}

// rectangular design
// export const radius = {
//   0: 0,
//   1: 0,
//   2: 0,
//   3: 0,
//   4: 0,
//   true: 0,
//   5: 0,
//   6: 0,
//   7: 0,
//   8: 0,
//   9: 0,
//   10: 0,
//   11: 0,
//   12: 0,
// }

// more rounded design
// export const radius = {
//   0: 0,
//   1: 9,
//   2: 10,
//   3: 16,
//   4: 19,
//   true: 19,
//   5: 22,
//   6: 26,
//   7: 34,
//   8: 42,
//   9: 50,
//   10: 60,
//   11: 72,
//   12: 84,
// }
