type Theme = {
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  color5: string;
  color6: string;
  color7: string;
  color8: string;
  color9: string;
  color10: string;
  color11: string;
  color12: string;
  background: string;
  backgroundHover: string;
  backgroundPress: string;
  backgroundFocus: string;
  backgroundStrong: string;
  backgroundTransparent: string;
  color: string;
  colorHover: string;
  colorPress: string;
  colorFocus: string;
  colorTransparent: string;
  borderColor: string;
  borderColorHover: string;
  borderColorFocus: string;
  borderColorPress: string;
  placeholderColor: string;
  outlineColor: string;
  blue1: string;
  blue2: string;
  blue3: string;
  blue4: string;
  blue5: string;
  blue6: string;
  blue7: string;
  blue8: string;
  blue9: string;
  blue10: string;
  blue11: string;
  blue12: string;
  gray1: string;
  gray2: string;
  gray3: string;
  gray4: string;
  gray5: string;
  gray6: string;
  gray7: string;
  gray8: string;
  gray9: string;
  gray10: string;
  gray11: string;
  gray12: string;
  green1: string;
  green2: string;
  green3: string;
  green4: string;
  green5: string;
  green6: string;
  green7: string;
  green8: string;
  green9: string;
  green10: string;
  green11: string;
  green12: string;
  purple1: string;
  purple2: string;
  purple3: string;
  purple4: string;
  purple5: string;
  purple6: string;
  purple7: string;
  purple8: string;
  purple9: string;
  purple10: string;
  purple11: string;
  purple12: string;
  red1: string;
  red2: string;
  red3: string;
  red4: string;
  red5: string;
  red6: string;
  red7: string;
  red8: string;
  red9: string;
  red10: string;
  red11: string;
  red12: string;
  yellow1: string;
  yellow2: string;
  yellow3: string;
  yellow4: string;
  yellow5: string;
  yellow6: string;
  yellow7: string;
  yellow8: string;
  yellow9: string;
  yellow10: string;
  yellow11: string;
  yellow12: string;
  brand1: string;
  brand2: string;
  brand3: string;
  brand4: string;
  brand5: string;
  brand6: string;
  brand7: string;
  brand8: string;
  brand9: string;
  brand10: string;
  brand11: string;
  brand12: string;
  brandHighlight: string;
  shadowColor: string;
  shadowColorHover: string;
  shadowColorPress: string;
  shadowColorFocus: string;

}

function t(a: [number, number][]) {
  let res: Record<string,string> = {}
  for (const [ki, vi] of a) {
    res[ks[ki] as string] = vs[vi] as string
  }
  return res as Theme
}
const vs = [
  '#fff',
  '#f9f9f9',
  'hsl(0, 0%, 97.3%)',
  'hsl(0, 0%, 95.1%)',
  'hsl(0, 0%, 94.0%)',
  'hsl(0, 0%, 92.0%)',
  'hsl(0, 0%, 89.5%)',
  'hsl(0, 0%, 81.0%)',
  'hsl(0, 0%, 56.1%)',
  'hsl(0, 0%, 50.3%)',
  'hsl(0, 0%, 42.5%)',
  'hsl(0, 0%, 9.0%)',
  'rgba(255,255,255,0)',
  'rgba(10,10,10,0)',
  'hsl(206, 100%, 99.2%)',
  'hsl(210, 100%, 98.0%)',
  'hsl(209, 100%, 96.5%)',
  'hsl(210, 98.8%, 94.0%)',
  'hsl(209, 95.0%, 90.1%)',
  'hsl(209, 81.2%, 84.5%)',
  'hsl(208, 77.5%, 76.9%)',
  'hsl(206, 81.9%, 65.3%)',
  'hsl(206, 100%, 50.0%)',
  'hsl(208, 100%, 47.3%)',
  'hsl(211, 100%, 43.2%)',
  'hsl(211, 100%, 15.0%)',
  'hsl(0, 0%, 99.0%)',
  'hsl(0, 0%, 93.0%)',
  'hsl(0, 0%, 90.9%)',
  'hsl(0, 0%, 88.7%)',
  'hsl(0, 0%, 85.8%)',
  'hsl(0, 0%, 78.0%)',
  'hsl(0, 0%, 52.3%)',
  'hsl(0, 0%, 43.5%)',
  'hsl(136, 50.0%, 98.9%)',
  'hsl(138, 62.5%, 96.9%)',
  'hsl(139, 55.2%, 94.5%)',
  'hsl(140, 48.7%, 91.0%)',
  'hsl(141, 43.7%, 86.0%)',
  'hsl(143, 40.3%, 79.0%)',
  'hsl(146, 38.5%, 69.0%)',
  'hsl(151, 40.2%, 54.1%)',
  'hsl(151, 55.0%, 41.5%)',
  'hsl(152, 57.5%, 37.6%)',
  'hsl(153, 67.0%, 28.5%)',
  'hsl(155, 40.0%, 14.0%)',
  'hsl(280, 65.0%, 99.4%)',
  'hsl(276, 100%, 99.0%)',
  'hsl(276, 83.1%, 97.0%)',
  'hsl(275, 76.4%, 94.7%)',
  'hsl(275, 70.8%, 91.8%)',
  'hsl(274, 65.4%, 87.8%)',
  'hsl(273, 61.0%, 81.7%)',
  'hsl(272, 60.0%, 73.5%)',
  'hsl(272, 51.0%, 54.0%)',
  'hsl(272, 46.8%, 50.3%)',
  'hsl(272, 50.0%, 45.8%)',
  'hsl(272, 66.0%, 16.0%)',
  'hsl(359, 100%, 99.4%)',
  'hsl(359, 100%, 98.6%)',
  'hsl(360, 100%, 96.8%)',
  'hsl(360, 97.9%, 94.8%)',
  'hsl(360, 90.2%, 91.9%)',
  'hsl(360, 81.7%, 87.8%)',
  'hsl(359, 74.2%, 81.7%)',
  'hsl(359, 69.5%, 74.3%)',
  'hsl(358, 75.0%, 59.0%)',
  'hsl(358, 69.4%, 55.2%)',
  'hsl(358, 65.0%, 48.7%)',
  'hsl(354, 50.0%, 14.6%)',
  'hsl(60, 54.0%, 98.5%)',
  'hsl(52, 100%, 95.5%)',
  'hsl(55, 100%, 90.9%)',
  'hsl(54, 100%, 86.6%)',
  'hsl(52, 97.9%, 82.0%)',
  'hsl(50, 89.4%, 76.1%)',
  'hsl(47, 80.4%, 68.0%)',
  'hsl(48, 100%, 46.1%)',
  'hsl(53, 92.0%, 50.0%)',
  'hsl(50, 100%, 48.5%)',
  'hsl(42, 100%, 29.0%)',
  'hsl(40, 55.0%, 13.5%)',
  'hsl(180, 29%, 17%)',
  'hsl(180, 36%, 22%)',
  'hsl(166, 30%, 29%)',
  'hsl(166, 55%, 31%)',
  'hsl(171, 96%, 28%)',
  'hsl(148, 44%, 47%)',
  'hsl(144, 55%, 57%)',
  'hsl(144, 73%, 68%)',
  'hsl(133, 54%, 78%)',
  'hsl(133, 63%, 83%)',
  'hsl(122, 53%, 88%)',
  'hsl(123, 50%, 93%)',
  'hsl(125, 50%, 96%)',
  'rgba(0,0,0,0.066)',
  'rgba(0,0,0,0.02)',
  '#050505',
  '#151515',
  '#191919',
  '#232323',
  '#282828',
  '#323232',
  '#424242',
  '#494949',
  '#545454',
  '#626262',
  '#a5a5a5',
  'hsl(212, 35.0%, 9.2%)',
  'hsl(216, 50.0%, 11.8%)',
  'hsl(214, 59.4%, 15.3%)',
  'hsl(214, 65.8%, 17.9%)',
  'hsl(213, 71.2%, 20.2%)',
  'hsl(212, 77.4%, 23.1%)',
  'hsl(211, 85.1%, 27.4%)',
  'hsl(211, 89.7%, 34.1%)',
  'hsl(209, 100%, 60.6%)',
  'hsl(210, 100%, 66.1%)',
  'hsl(206, 98.0%, 95.8%)',
  'hsl(0, 0%, 8.5%)',
  'hsl(0, 0%, 11.0%)',
  'hsl(0, 0%, 13.6%)',
  'hsl(0, 0%, 15.8%)',
  'hsl(0, 0%, 17.9%)',
  'hsl(0, 0%, 20.5%)',
  'hsl(0, 0%, 24.3%)',
  'hsl(0, 0%, 31.2%)',
  'hsl(0, 0%, 43.9%)',
  'hsl(0, 0%, 49.4%)',
  'hsl(0, 0%, 62.8%)',
  'hsl(146, 30.0%, 7.4%)',
  'hsl(155, 44.2%, 8.4%)',
  'hsl(155, 46.7%, 10.9%)',
  'hsl(154, 48.4%, 12.9%)',
  'hsl(154, 49.7%, 14.9%)',
  'hsl(154, 50.9%, 17.6%)',
  'hsl(153, 51.8%, 21.8%)',
  'hsl(151, 51.7%, 28.4%)',
  'hsl(151, 49.3%, 46.5%)',
  'hsl(151, 50.0%, 53.2%)',
  'hsl(137, 72.0%, 94.0%)',
  'hsl(284, 20.0%, 9.6%)',
  'hsl(283, 30.0%, 11.8%)',
  'hsl(281, 37.5%, 16.5%)',
  'hsl(280, 41.2%, 20.0%)',
  'hsl(279, 43.8%, 23.3%)',
  'hsl(277, 46.4%, 27.5%)',
  'hsl(275, 49.3%, 34.6%)',
  'hsl(272, 52.1%, 45.9%)',
  'hsl(273, 57.3%, 59.1%)',
  'hsl(275, 80.0%, 71.0%)',
  'hsl(279, 75.0%, 95.7%)',
  'hsl(353, 23.0%, 9.8%)',
  'hsl(357, 34.4%, 12.0%)',
  'hsl(356, 43.4%, 16.4%)',
  'hsl(356, 47.6%, 19.2%)',
  'hsl(356, 51.1%, 21.9%)',
  'hsl(356, 55.2%, 25.9%)',
  'hsl(357, 60.2%, 31.8%)',
  'hsl(358, 65.0%, 40.4%)',
  'hsl(358, 85.3%, 64.0%)',
  'hsl(358, 100%, 69.5%)',
  'hsl(351, 89.0%, 96.0%)',
  'hsl(45, 100%, 5.5%)',
  'hsl(46, 100%, 6.7%)',
  'hsl(45, 100%, 8.7%)',
  'hsl(45, 100%, 10.4%)',
  'hsl(47, 100%, 12.1%)',
  'hsl(49, 100%, 14.3%)',
  'hsl(49, 90.3%, 18.4%)',
  'hsl(50, 100%, 22.0%)',
  'hsl(54, 100%, 68.0%)',
  'hsl(48, 100%, 47.0%)',
  'hsl(53, 100%, 91.0%)',
  'hsl(125, 100%, 98%)',
  'hsl(180, 41%, 8%)',
  'rgba(0,0,0,0.3)',
  'rgba(0,0,0,0.2)',
  'hsla(60, 54.0%, 98.5%, 0)',
  'hsla(40, 55.0%, 13.5%, 0)',
  'hsla(136, 50.0%, 98.9%, 0)',
  'hsla(155, 40.0%, 14.0%, 0)',
  'hsla(206, 100%, 99.2%, 0)',
  'hsla(211, 100%, 15.0%, 0)',
  'hsla(280, 65.0%, 99.4%, 0)',
  'hsla(272, 66.0%, 16.0%, 0)',
  'hsla(359, 100%, 99.4%, 0)',
  'hsla(354, 50.0%, 14.6%, 0)',
  'hsla(180, 29%, 17%, 0)',
  'hsla(125, 50%, 96%, 0)',
  'hsla(45, 100%, 5.5%, 0)',
  'hsla(53, 100%, 91.0%, 0)',
  'hsla(146, 30.0%, 7.4%, 0)',
  'hsla(137, 72.0%, 94.0%, 0)',
  'hsla(212, 35.0%, 9.2%, 0)',
  'hsla(206, 98.0%, 95.8%, 0)',
  'hsla(284, 20.0%, 9.6%, 0)',
  'hsla(279, 75.0%, 95.7%, 0)',
  'hsla(353, 23.0%, 9.8%, 0)',
  'hsla(351, 89.0%, 96.0%, 0)',
  'hsla(123, 50%, 93%, 0)',
  'hsla(180, 41%, 8%, 0)',
]

const ks = [
'color1',
'color2',
'color3',
'color4',
'color5',
'color6',
'color7',
'color8',
'color9',
'color10',
'color11',
'color12',
'background',
'backgroundHover',
'backgroundPress',
'backgroundFocus',
'backgroundStrong',
'backgroundTransparent',
'color',
'colorHover',
'colorPress',
'colorFocus',
'colorTransparent',
'borderColor',
'borderColorHover',
'borderColorFocus',
'borderColorPress',
'placeholderColor',
'outlineColor',
'blue1',
'blue2',
'blue3',
'blue4',
'blue5',
'blue6',
'blue7',
'blue8',
'blue9',
'blue10',
'blue11',
'blue12',
'gray1',
'gray2',
'gray3',
'gray4',
'gray5',
'gray6',
'gray7',
'gray8',
'gray9',
'gray10',
'gray11',
'gray12',
'green1',
'green2',
'green3',
'green4',
'green5',
'green6',
'green7',
'green8',
'green9',
'green10',
'green11',
'green12',
'purple1',
'purple2',
'purple3',
'purple4',
'purple5',
'purple6',
'purple7',
'purple8',
'purple9',
'purple10',
'purple11',
'purple12',
'red1',
'red2',
'red3',
'red4',
'red5',
'red6',
'red7',
'red8',
'red9',
'red10',
'red11',
'red12',
'yellow1',
'yellow2',
'yellow3',
'yellow4',
'yellow5',
'yellow6',
'yellow7',
'yellow8',
'yellow9',
'yellow10',
'yellow11',
'yellow12',
'brand1',
'brand2',
'brand3',
'brand4',
'brand5',
'brand6',
'brand7',
'brand8',
'brand9',
'brand10',
'brand11',
'brand12',
'brandHighlight',
'shadowColor',
'shadowColorHover',
'shadowColorPress',
'shadowColorFocus']


const n1 = t([[0, 0],[1, 1],[2, 2],[3, 3],[4, 4],[5, 5],[6, 6],[7, 7],[8, 8],[9, 9],[10, 10],[11, 11],[12, 1],[13, 2],[14, 3],[15, 4],[16, 0],[17, 12],[18, 11],[19, 10],[20, 11],[21, 10],[22, 13],[23, 4],[24, 5],[25, 3],[26, 4],[27, 8],[28, 4],[29, 14],[30, 15],[31, 16],[32, 17],[33, 18],[34, 19],[35, 20],[36, 21],[37, 22],[38, 23],[39, 24],[40, 25],[41, 26],[42, 2],[43, 3],[44, 27],[45, 28],[46, 29],[47, 30],[48, 31],[49, 8],[50, 32],[51, 33],[52, 11],[53, 34],[54, 35],[55, 36],[56, 37],[57, 38],[58, 39],[59, 40],[60, 41],[61, 42],[62, 43],[63, 44],[64, 45],[65, 46],[66, 47],[67, 48],[68, 49],[69, 50],[70, 51],[71, 52],[72, 53],[73, 54],[74, 55],[75, 56],[76, 57],[77, 58],[78, 59],[79, 60],[80, 61],[81, 62],[82, 63],[83, 64],[84, 65],[85, 66],[86, 67],[87, 68],[88, 69],[89, 70],[90, 71],[91, 72],[92, 73],[93, 74],[94, 75],[95, 76],[96, 77],[97, 78],[98, 79],[99, 80],[100, 81],[101, 82],[102, 83],[103, 84],[104, 85],[105, 86],[106, 87],[107, 88],[108, 89],[109, 90],[110, 91],[111, 92],[112, 93],[113, 94],[114, 95],[115, 95],[116, 96],[117, 96]])

export const light = n1
const n2 = t([[0, 97],[1, 98],[2, 99],[3, 100],[4, 101],[5, 102],[6, 103],[7, 104],[8, 105],[9, 106],[10, 107],[11, 0],[12, 98],[13, 99],[14, 100],[15, 101],[16, 97],[17, 13],[18, 0],[19, 107],[20, 0],[21, 107],[22, 12],[23, 101],[24, 102],[25, 100],[26, 101],[27, 105],[28, 101],[29, 108],[30, 109],[31, 110],[32, 111],[33, 112],[34, 113],[35, 114],[36, 115],[37, 22],[38, 116],[39, 117],[40, 118],[41, 119],[42, 120],[43, 121],[44, 122],[45, 123],[46, 124],[47, 125],[48, 126],[49, 127],[50, 128],[51, 129],[52, 27],[53, 130],[54, 131],[55, 132],[56, 133],[57, 134],[58, 135],[59, 136],[60, 137],[61, 42],[62, 138],[63, 139],[64, 140],[65, 141],[66, 142],[67, 143],[68, 144],[69, 145],[70, 146],[71, 147],[72, 148],[73, 54],[74, 149],[75, 150],[76, 151],[77, 152],[78, 153],[79, 154],[80, 155],[81, 156],[82, 157],[83, 158],[84, 159],[85, 66],[86, 160],[87, 161],[88, 162],[89, 163],[90, 164],[91, 165],[92, 166],[93, 167],[94, 168],[95, 169],[96, 170],[97, 78],[98, 171],[99, 172],[100, 173],[101, 93],[102, 92],[103, 174],[104, 85],[105, 86],[106, 87],[107, 88],[108, 89],[109, 90],[110, 84],[111, 83],[112, 82],[113, 175],[114, 176],[115, 176],[116, 177],[117, 177]])

export const dark = n2
const n3 = t([[0, 70],[1, 71],[2, 72],[3, 73],[4, 74],[5, 75],[6, 77],[7, 78],[8, 79],[9, 80],[10, 81],[11, 11],[12, 71],[13, 72],[14, 73],[15, 74],[16, 70],[17, 178],[18, 11],[19, 81],[20, 11],[21, 81],[22, 179],[23, 73],[24, 74],[25, 73],[26, 73],[27, 79],[28, 74]])

export const light_yellow = n3
const n4 = t([[0, 34],[1, 35],[2, 36],[3, 37],[4, 38],[5, 39],[6, 41],[7, 42],[8, 43],[9, 44],[10, 45],[11, 11],[12, 35],[13, 36],[14, 37],[15, 38],[16, 34],[17, 180],[18, 11],[19, 45],[20, 11],[21, 45],[22, 181],[23, 37],[24, 38],[25, 37],[26, 37],[27, 43],[28, 38]])

export const light_green = n4
const n5 = t([[0, 14],[1, 15],[2, 16],[3, 17],[4, 18],[5, 19],[6, 21],[7, 22],[8, 23],[9, 24],[10, 25],[11, 11],[12, 15],[13, 16],[14, 17],[15, 18],[16, 14],[17, 182],[18, 11],[19, 25],[20, 11],[21, 25],[22, 183],[23, 17],[24, 18],[25, 17],[26, 17],[27, 23],[28, 18]])

export const light_blue = n5
const n6 = t([[0, 46],[1, 47],[2, 48],[3, 49],[4, 50],[5, 51],[6, 53],[7, 54],[8, 55],[9, 56],[10, 57],[11, 11],[12, 47],[13, 48],[14, 49],[15, 50],[16, 46],[17, 184],[18, 11],[19, 57],[20, 11],[21, 57],[22, 185],[23, 49],[24, 50],[25, 49],[26, 49],[27, 55],[28, 50]])

export const light_purple = n6
const n7 = t([[0, 58],[1, 59],[2, 60],[3, 61],[4, 62],[5, 63],[6, 65],[7, 66],[8, 67],[9, 68],[10, 69],[11, 11],[12, 59],[13, 60],[14, 61],[15, 62],[16, 58],[17, 186],[18, 11],[19, 69],[20, 11],[21, 69],[22, 187],[23, 61],[24, 62],[25, 61],[26, 61],[27, 67],[28, 62]])

export const light_red = n7
const n8 = t([[0, 82],[1, 83],[2, 84],[3, 85],[4, 86],[5, 87],[6, 90],[7, 91],[8, 92],[9, 93],[10, 94],[11, 11],[12, 83],[13, 84],[14, 85],[15, 86],[16, 82],[17, 188],[18, 11],[19, 94],[20, 11],[21, 94],[22, 189],[23, 85],[24, 86],[25, 85],[26, 85],[27, 92],[28, 86]])

export const light_brand = n8
const n9 = t([[0, 163],[1, 164],[2, 165],[3, 166],[4, 167],[5, 168],[6, 170],[7, 78],[8, 171],[9, 172],[10, 173],[11, 0],[12, 164],[13, 165],[14, 166],[15, 167],[16, 163],[17, 190],[18, 0],[19, 173],[20, 0],[21, 173],[22, 191],[23, 167],[24, 168],[25, 166],[26, 167],[27, 171],[28, 167]])

export const dark_yellow = n9
const n10 = t([[0, 130],[1, 131],[2, 132],[3, 133],[4, 134],[5, 135],[6, 137],[7, 42],[8, 138],[9, 139],[10, 140],[11, 0],[12, 131],[13, 132],[14, 133],[15, 134],[16, 130],[17, 192],[18, 0],[19, 140],[20, 0],[21, 140],[22, 193],[23, 134],[24, 135],[25, 133],[26, 134],[27, 138],[28, 134]])

export const dark_green = n10
const n11 = t([[0, 108],[1, 109],[2, 110],[3, 111],[4, 112],[5, 113],[6, 115],[7, 22],[8, 116],[9, 117],[10, 118],[11, 0],[12, 109],[13, 110],[14, 111],[15, 112],[16, 108],[17, 194],[18, 0],[19, 118],[20, 0],[21, 118],[22, 195],[23, 112],[24, 113],[25, 111],[26, 112],[27, 116],[28, 112]])

export const dark_blue = n11
const n12 = t([[0, 141],[1, 142],[2, 143],[3, 144],[4, 145],[5, 146],[6, 148],[7, 54],[8, 149],[9, 150],[10, 151],[11, 0],[12, 142],[13, 143],[14, 144],[15, 145],[16, 141],[17, 196],[18, 0],[19, 151],[20, 0],[21, 151],[22, 197],[23, 145],[24, 146],[25, 144],[26, 145],[27, 149],[28, 145]])

export const dark_purple = n12
const n13 = t([[0, 152],[1, 153],[2, 154],[3, 155],[4, 156],[5, 157],[6, 159],[7, 66],[8, 160],[9, 161],[10, 162],[11, 0],[12, 153],[13, 154],[14, 155],[15, 156],[16, 152],[17, 198],[18, 0],[19, 162],[20, 0],[21, 162],[22, 199],[23, 156],[24, 157],[25, 155],[26, 156],[27, 160],[28, 156]])

export const dark_red = n13
const n14 = t([[0, 93],[1, 92],[2, 174],[3, 85],[4, 86],[5, 87],[6, 90],[7, 84],[8, 83],[9, 82],[10, 175],[11, 0],[12, 92],[13, 174],[14, 85],[15, 86],[16, 93],[17, 200],[18, 0],[19, 175],[20, 0],[21, 175],[22, 201],[23, 86],[24, 87],[25, 85],[26, 86],[27, 83],[28, 86]])

export const dark_brand = n14